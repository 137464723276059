import Myparticle from './Particless.js';
import React,{useState, useEffect, useRef} from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth ,onAuthStateChanged} from "firebase/auth";
import { doc, getDoc, updateDoc} from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";
import fetch from 'node-fetch';
import AdminNav from "./navbars/Nav";
import Table from './Table';
import Loader from './Loader.js';
import Ex3 from './Ex3.js'
import { ethers } from "ethers";
import FundV1 from "./contracts/FundV1.json";
import config from '../config.json'
import AdminDashboard from './AdminDashboard.js';




function Dashboard(props) {
  const {currentUser} = props;//Current user passed from props
  const [email, setEmail] = useState('')//Keeps track of the Email of the current user 
  const [isConnected, setIsConnected] = useState(false) //Keeps track of whether user connected their wallet to
  const [signer, setSigner] = useState({})
  const [provider, setProvider] = useState({}) 
  const [msg, setMsg] = useState("");
  const [user, setUser] = useState(null) //Keeps track of current user
  const [investedAmount, setInvestedAmount] = useState('');//Keeps track of Invested amount
  const [portfolioGrowth, setPortfolioGrowth] = useState('');//Keeps track of portfolio growth
  const [uid, setUid] = useState('');//uid of currently logged in user
  const [isDeployed , setIsDeployed] = useState(true);//Whether fund contract was deployed
  const [performanceFee, setPerformanceFee] = useState(null);
  const [managerWithdrawEnabled, setManagerWithdrawEnabled] = useState(null);
  const [historicalData, setHistoricalData] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [chainId, setChainId] = useState('');
  const [chain, setChain] = useState('');
  const [onchainDeployed, setOnChainDeployed] = useState(false);
  const [withdrawEnabled, setWithdrawEnabled] = useState(false);
  const [currentContract, setCurrentContract] = useState('');
  const [ethContract, setEthContract] = useState('');
  const [arbContract, setArbContract] = useState('');
  const [polyContract, setPolyContract] = useState('');
  const [testContract, setTestContract] = useState('');
  const [totalAum, settotalAum] = useState('');
  const [totalInvested, settotalInvested] = useState('');
  const [totalAumGrowth, settotalAumGrowth] = useState('');

  const deployRef = useRef(null)
  const withdrawRef = useRef(null)
  

  const apiUrl = "https://ironglassinvestment.com:3004/"
 //const apiUrl = "http://localhost:3004/"


  const [startData, setStartData] = useState([]);//Final data that will be displayed in table
  const [finaldata, setfinaldata] = useState([]);//Final data that will be displayed in table
  const [loading,setLoading] = useState(true); //Whether table data is loading or not
  const [totalbalance, settotalbalance] = useState('');//Total balance of portfolio

  //Firebase DB credentials
  const firebaseConfig = {
        apiKey: "AIzaSyCFA3Sa14Uokttej7rC7VFGSIePenYB6ac",
        projectId: "igi2-8ba54",
        storageBucket: "gs://igi2-8ba54.appspot.com",
  };

  //Get the the current user
  async function getUser(){
    console.log('curruser',currentUser)
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        const uid = user.uid;
        setUid(uid)
        setEmail(user.email)
        if(user.email === "osazemoore517@gmail.com" || user.email === "sam.cps@ironglassinvestment.com"){
          setAdmin(true)
        }
        console.log("User UID is ", uid)
      } else {
        //const uid = user.uid;
        //setUid(uid)
        // User is signed out
        console.log("No user Found")
      }
    });
  }

  //Function to get current portFolio information
  async function getPort(){
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const docSnap = await getDoc(doc(db, 'portfolios', uid));
    if (docSnap.exists()) {

      if(docSnap.data()){
        setInvestedAmount(docSnap.data().invested)
        setIsDeployed(docSnap.data().Deployed)
        setPerformanceFee(docSnap.data().performanceFee)
        setManagerWithdrawEnabled(docSnap.data().managerWithdrawEnabled)
      }
      
      let currentInvestment = docSnap.data().invested


      let cryptos = {}
      let table1 = []
      let FinalTable = []
      let total = 0;
      let piearr = []
      // Get value of ETH contact and add it to te table
      if(docSnap.data().eth_contract){
        let address = docSnap.data().eth_contract
        const allocations = await fetch(`${apiUrl}portfolio?address=${address}&chainId=1`)
        const all = await allocations.json()
        let ethPrice1 = await fetch(`https://api.coinbase.com/v2/prices/ETH-USD/spot`);
        let data = await ethPrice1.json();
        let ethPrice= Number(data.data.amount)
        let ethNative = all.native
        //console.log("ETH native")
        let tokens = all.tokens
        let ethUsdBal = 0
        if(ethNative > 0){
          ethUsdBal = ethNative * ethPrice
          total +=ethUsdBal
          let row = {
            asset: "ETH",
            cryptoBalance: ethNative,
            cashBalance: ethUsdBal,
            price: ethPrice,        
          }
          table1.push(row)
        }
        for(let i=0; i<tokens.length; i++){
          console.log(tokens[i])
          let tokenNative = tokens[i].balance
          let symbol = tokens[i].symbol
          let decimals = tokens[i].decimals
          let address = tokens[i].token_address
          let tokenPrice1 = await fetch(`${apiUrl}price?address=${address}&chainId=1`)
          let data = await tokenPrice1.json();
          let tokenPrice= data.usdPrice.toFixed(2)
          let tokenUsdValue = (tokenNative/(10 ** decimals)) * tokenPrice
          let row = {
            asset: symbol,
            cryptoBalance: tokenNative,
            cashBalance: tokenUsdValue,
            price: tokenPrice,        
          }
          table1.push(row)
          total+=tokenUsdValue
        }
        setEthContract(docSnap.data().eth_contract)
      }
      // Get value of Arbitrum contract and add it to the table
      if(docSnap.data().arb_contract){
        let address = docSnap.data().arb_contract
        const allocations = await fetch(`${apiUrl}portfolio?address=${address}&chainId=42161`)
        const all = await allocations.json()
        let ethPrice1 = await fetch(`https://api.coinbase.com/v2/prices/ETH-USD/spot`);
        let data = await ethPrice1.json();
        let ethPrice= Number(data.data.amount)
        let ethNative = all.native
        console.log("ETH native", ethNative)
        let tokens = all.tokens
        let ethUsdBal = 0
        if(ethNative > 0){
          ethUsdBal = ethNative * ethPrice
          total += ethUsdBal
          let eth = table1.findIndex(p => p.asset == "ETH");
          if(eth != -1){
            table1[eth].cashBalance += ethUsdBal
            //table1[eth].cryptoBalance +=  ethNative
          }else{
            let row = {
              asset: "ETH",
              cryptoBalance: ethNative,
              cashBalance: Number(ethUsdBal.toFixed(2)),
              price: ethPrice,  
              icon: "https://fbi.cults3d.com/uploaders/21728736/illustration-file/2f425d7e-aa53-494b-b2e8-fbb99763dd6f/eth.png"
            }
            table1.push(row)
          }
        }
        for(let i=0; i<tokens.length; i++){
          if(tokens[i].token_address != "0x76e3c719ee614f2b7c8107b97c78ddfd3cf3294a" && tokens[i].token_address!="0xfcadb88b8eb5d45a901a8a66ab0c10934cfc626a"  && tokens[i].token_address!="0x858824699726bde47d8a1a1f578ada760f6547d7" && tokens[i].token_address!="0x5d3300a619a935670623086c0a5b6620040c3344"&& tokens[i].possible_spam==false){
            console.log(tokens[i])
            let tokenNative = tokens[i].balance
            let symbol = tokens[i].symbol
            let decimals = tokens[i].decimals
            let address = tokens[i].token_address
            let tokenPrice1 = await fetch(`${apiUrl}price?address=${address}&chainId=42161`)
            let data = await tokenPrice1.json();
            let tokenPrice= data.usdPrice.toFixed(2)
            console.log("tokenNative " + tokenNative)
            let tokenUsdValue = (tokenNative/(10 ** decimals)) * tokenPrice
            total+=tokenUsdValue
            let token = table1.findIndex(p => p.asset == symbol);
            if(token != -1){
              table1[token].cashBalance += tokenUsdValue
              table1[token].cryptoBalance += tokenNative
            }else{
              let row = {
                asset: symbol,
                cryptoBalance: tokenNative,
                cashBalance: Number(tokenUsdValue.toFixed(2)),
                price: tokenPrice,  
                icon:tokens[i].thumbnail     
              }
              table1.push(row)
            }
          }
        }
        setArbContract(docSnap.data().arb_contract)
      }
      // Get value of coinbase portfolio and add it to the table
      if(docSnap.data().coinbaseReadAccess){
        let  tokens = await fetch(`${apiUrl}coinbasePortfolio?uid=${uid}`)
        tokens = await tokens.json()
        tokens = tokens.balances
        console.log("coinbase", tokens)
        for(let i=0; i<tokens.length; i++){
          if(tokens[i].symbol === "eth"){
            let ethNative = Number(tokens[i].balance)

            //Check to see if ETH is already in table
            let eth = table1.findIndex(p => p.asset == "ETH");

            //If it is add the balance from coinbase to balance in table
            if(eth != -1){
              let ethUsdBal = table1[eth].price * ethNative
              table1[eth].cashBalance += Number(tokens[i].fiat_amount)
              total+=Number(tokens[i].fiat_amount)
              //table1[eth].cryptoBalance +=  ethNative
            }
            //If its not set the ETH value in the table 
            else{
              let ethPrice1 = await fetch(`https://api.coinbase.com/v2/prices/ETH-USD/spot`);
              let data = await ethPrice1.json();
              let ethPrice= Number(data.data.amount)
              let ethUsdBal = Number(tokens[i].fiat_amount)
              let row = {
                asset: "ETH",
                cryptoBalance: ethNative,
                cashBalance: ethUsdBal,
                price: ethPrice,  
                icon: "https://fbi.cults3d.com/uploaders/21728736/illustration-file/2f425d7e-aa53-494b-b2e8-fbb99763dd6f/eth.png"
              }
              table1.push(row)
              total+=Number(ethUsdBal)
            }
          }else{
            //Check to see if currency is in the table already
            let currency = table1.findIndex(p => p.asset == tokens[i].symbol);
            let currencyNative = tokens[i].balance

            //If it is add the balance from coinbase to balance in table
            if(currency != -1){
              let currencyUsdBal = Number(tokens[i].fiat_amount)
              table1[currency].cashBalance += Number(tokens[i].fiat_amount)
              total+=Number(currencyUsdBal)
              table1[currency].cryptoBalance +=  currencyNative
            }
            //If its not set the currency value in the table 
            else{
              let currencyPrice
              if(tokens[i].symbol != 'usd'){
                let currencyPrice1 = await fetch(`https://api.coinbase.com/v2/prices/${tokens[i].symbol}-USD/spot`);
                let data = await currencyPrice1.json();
                currencyPrice= Number(data.data.amount)
              }
              else{
                currencyPrice= 1
              }
              
              let currencyUsdBal = Number(tokens[i].fiat_amount)
              let row = {
                asset: tokens[i].symbol,
                cryptoBalance: currencyNative,
                cashBalance: Number(tokens[i].fiat_amount),
                price: currencyPrice,  
                icon: "https://fbi.cults3d.com/uploaders/21728736/illustration-file/2f425d7e-aa53-494b-b2e8-fbb99763dd6f/eth.png"
              }
              table1.push(row)
              total+=Number(currencyUsdBal)
            }
          }
        }
      }
      // If no portfolio on any chain show default
      // else{
      //   cryptos = docSnap.data().Default
      //   for(let i = 0; i < cryptos.length; i++){
      //     console.log('console', cryptos[i].currency)
      //     console.log('console', cryptos[i].amount)
      //     //table1.push(cryptos[i].currency)
      //     if(cryptos[i].currency === 'USD'){
      //     let balconvert = Number(cryptos[i].amount,2)
      //     let row = {
      //       asset: cryptos[i].currency,
      //       cryptoBalance: balconvert,
      //       cashBalance: balconvert,
      //       price: 1,
      //     }
      //     table1.push(row)
      //     total+=balconvert
      //     console.log("Total amount is ", total)
      //     // console.log("total", total)
      //     }else{
      //       if(cryptos[i].amount > 0){
      //       const currentCrypto = cryptos[i].currency
      //       const response3 = await fetch(`https://api.coinbase.com/v2/prices/${currentCrypto}-USD/spot`);
      //       const data = await response3.json();
      //       const totalAmountUsd = Number(data.data.amount) * Number(cryptos[i].amount)
      //       total+=totalAmountUsd
      //       let row = {
      //         asset: cryptos[i].currency,
      //         cryptoBalance: Number(cryptos[i].amount),
      //         cashBalance: totalAmountUsd.toFixed(2),
      //         price: Number(data.data.amount),
      //         //icon: GetCryptoIcons(cryptos[i].currency)
      //       }
      //       table1.push(row)
      //       console.log("Total amount is ", total)
      //       }
      //     }
      //   }
      // }

      console.log("First table", table1)

      for(let i = 0; i < table1.length;i++){
        const currenyTotal = table1[i].cashBalance
        const currenyPercentage = (currenyTotal/total) * 100 
        let cryptoQuantity = 0
        if(table1[i].cashBalance > .01){
          cryptoQuantity = table1[i].cryptoBalance
          const currentPrice = table1[i].price
          let row2 = {
            Asset: table1[i].asset,
            Holdings: currenyPercentage.toFixed(2),
            Balance: table1[i].cashBalance,
            Quantity: cryptoQuantity,
            Price: currentPrice,
            Icon: table1[i].icon
          }
          FinalTable.push(row2)
        }
      }

      for(let i = 0; i < FinalTable.length;i++){
        let val = Math.trunc(FinalTable[i].Holdings)
        console.log('val',val)
        let row = {name:FinalTable[i].Asset,value:val}
        piearr.push(row)
      }

      total = Number(total.toFixed(2))
      console.log("total", total)
      console.log("invested", investedAmount)
      console.log("Final table", FinalTable)

      
      //Calulate total growth
      const totalGrowth = (total - currentInvestment) / currentInvestment * 100 
      console.log("totalGrowth is ", totalGrowth , "%")
      settotalbalance(total.toFixed(2))
      setPortfolioGrowth(Number(totalGrowth.toFixed(2)))
      //setPortfolioGrowth(0)

      //Sort by decending balance
      const sortedTable = FinalTable.sort(function(a,b){
        return a.Balance - b.Balance;
      })

      const descending = sortedTable.reverse()
      setfinaldata(descending)
      setLoading(false)
      
      //console.log("Cryptos from database", cryptos)
    } else {
      console.log("No user porfolio exists");
    }

    if(!admin){
      const historicalCollections = [
        'historical', 'historical2', 'historical3', 'historical4',
        'historical5', 'historical6', 'historical7', 'historical8'
      ];
      
      try {
        const docSnapshots = await Promise.all(
          historicalCollections.map(collection => getDoc(doc(db, collection, uid)))
        );
      
        let total = [];
        
        docSnapshots.forEach(docSnap => {
          if (docSnap.exists()) {
            total = total.concat(docSnap.data().total || []);
          }
        });
      
        setHistoricalData(total);
      } catch (error) {
        console.error("Error fetching historical data:", error);
      }

      if(docSnap.data().name === "Osaze" || docSnap.data().name === "Jake" || docSnap.data().name === "James" || docSnap.data().name === "Sam"){
        const docSnap = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik7"));
        const docSnap5 = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik12"));
        let totalAum = docSnap.data().currentAum.toFixed(2)
        let totalInvested = docSnap5.data().totalInvested.toFixed(2)
        let totalGrowth = Number((((totalAum - totalInvested) / totalInvested) * 100).toFixed(2))
        settotalAum(totalAum)
        settotalInvested(totalInvested)
        settotalAumGrowth(totalGrowth)
      }
      
    }
  }

  //Funtion for user to connect their wallet 
  async function connectWallet(){
    let signer = null;
    let provider;
    if (window.ethereum == null) {
        // If MetaMask is not installed, we use the default provider,
        // which is backed by a variety of third-party services (such
        // as INFURA). They do not have private keys installed so are
        // only have read-only access
        console.log("MetaMask not installed; using read-only defaults")
        provider = ethers.getDefaultProvider()
        const { chainId } = await provider.getNetwork()
        console.log("chain id is " + chainId.toString(16)) 
        setChainId(chainId.toString(16))
        signer = await provider.getSigner();
        setSigner(signer)
        if(chainId.toString() === "1"){
          setChain("Ethereum")
        }else if(chainId.toString() === "42161"){
          setChain("Arbitrum")
        }
        else if (chainId.toString() === "137"){
          setChain("Polygon")
        }else if(chainId.toString() === "31337"){
          setChain("Hardhat")
        }
    } else {

        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.
        provider = new ethers.BrowserProvider(window.ethereum)
        setProvider(provider)

        // It also provides an opportunity to request access to write
        // operations, which will be performed by the private key
        // that MetaMask manages for the user.
        signer = await provider.getSigner();
        setSigner(signer)
        const { chainId } = await provider.getNetwork()
        setChainId(chainId.toString(16))
        console.log("chain id is " + chainId.toString(16)) 
        if(chainId.toString() === "1" && ethContract){
          setWithdrawEnabled(true)
          setOnChainDeployed(true)
          setChain("Ethereum")
          setCurrentContract(ethContract)
        }else if (chainId.toString() === "42161" && arbContract){
          setOnChainDeployed(true)
          setWithdrawEnabled(true)
          setChain("Arbitrum")
          setCurrentContract(arbContract)
        }
        else if (chainId.toString() === "137" && polyContract){
          setOnChainDeployed(true)
          setChain("Polygon")
          setCurrentContract(polyContract)
        }else if(chainId.toString() === "31337"&& testContract){
          setOnChainDeployed(true)
          setChain("Hardhat")
          setCurrentContract(testContract)
        }

        if(chainId.toString() === "1"){
          setChain("Ethereum")
        }else if(chainId.toString() === "42161"){
          setChain("Arbitrum")
        }
        else if (chainId.toString() === "137"){
          setChain("Polygon")
        }else if(chainId.toString() === "31337"){
          setChain("Hardhat")
        }
    } 
    setIsConnected(true)
  }

  //Function to handle network changes
  const networkHandler = async (e) =>{
    await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: e.target.value}]
    })
    setChainId(e.target.value)
    if(e.target.value === "0x1"){
      setChain("Ethereum")
    }else if (e.target.value === "0x89"){
      setChain("Arbitrum")
    }
    else if (e.target.value === "0x89"){
      setChain("Polygon")
    }else if(e.target.value === "0x7a69"){
      setChain("Hardhat")
    }
  }

  //Function for user to deploy their fund
  async function deployFund(){
    const gasTrackingEnabled = true
    console.log(provider)
    console.log(signer)
    console.log(performanceFee)
    console.log(managerWithdrawEnabled)
    let InvestorFund = new ethers.ContractFactory(FundV1.abi, FundV1.bytecode, signer);
    let investorFund = await InvestorFund.deploy("0x8C82360CFF50AA3f9006E35952ef923Df0a148f4" , performanceFee, managerWithdrawEnabled, gasTrackingEnabled);
    console.log(investorFund)
    const db = getFirestore();
    if(!isDeployed){
      await updateDoc(doc(db, "portfolios", uid), {
        Deployed: true,
      });
    }
    if(chainId.toString() === "1" || chainId.toString() == "1"){
      await updateDoc(doc(db, "portfolios", uid), {
        eth_contract: investorFund.target
      });
    }else if(chainId.toString() === "42161" || chainId.toString() === "a4b1" ){
      await updateDoc(doc(db, "portfolios", uid), {
        arb_contract: investorFund.target
      });
    }
    alert(`Contract deployed to  ${investorFund.target} on ${chain} network`)
    setIsDeployed(true)
    console.log(chainId)
    console.log(uid)
    console.log(investorFund.target)

    //Add fund to be tracked by master on backend
    //const add = await fetch(`http://localhost:3004/add?chainId=${chainId}?user=${uid}?contract=${investorFund.target}`)
  }

  //Function for user to deploy their fund
  async function withdraw(){
    try{
      const fund = new ethers.Contract(currentContract, FundV1.abi, signer)
      let tx= await fund.ownerWithdraw(14000000)
      console.log(`Transaction hash: ${tx.hash}`);
      const receipt = await tx.wait();
      console.log(`Gas used: ${receipt.gasUsed.toString()}`);
      alert(`Transaction confirmed in hash: ${tx.hash}`);
    } catch (err){
      alert(err);
    }
  }

  useEffect(() => {
    getUser()
  }, [email,user]);

  useEffect(() => {
    getPort()
    //console.log(startData)
  }, [uid]);
    return (
      <div classname="mainpage">
        <Myparticle/>
        <AdminNav/>
        <div className="section"> 
          <div className='flex justify-center flex-row min-w-full place-content-center'>
              {admin ? 
                <AdminDashboard/>
                : 
                <div className="card block text-white mb-9 min-h-screen">
                  <div className="statscards-container">
                    {totalInvested && (
                        <div className='statscards text-center'>
                            <h6 className='m-5 orbitron'>Total Invested</h6>
                            <h1 className='text-[#10b981] m-5 orbitron'>$ {totalInvested}</h1>
                        </div>
                    )}

                    {totalAum && (
                        <div className='statscards text-center'>
                            <h6 className='m-5 orbitron'>Current AUM</h6>
                            <h1 className='text-[#10b981] m-5 orbitron'>$ {totalAum} </h1>
                        </div>
                    )}
                    {totalAumGrowth && (
                        <div className='statscards text-center'>
                            <h6 className='m-5 orbitron'>Total AUM growth</h6>
                            <h1 className='text-[#10b981] m-5 orbitron'>{totalAumGrowth} %</h1>
                        </div>
                    )}
                  </div>
                      {historicalData ? 
                        (<Ex3 historicalData={historicalData}/>) : 
                          <div className='flex justify-center mt-40'>
                            < Loader loading={loading}/>
                          </div>
                      }
                      <div className="statscards-container">
                        <div className='statscards text-center'>
                          <h6 className='m-5 orbitron'>Total Balance</h6>
                          <h1 className='text-[#10b981] m-5 orbitron'>$ {totalbalance} </h1>
                        </div>
                        <div className='statscards text-center'>
                          <h4 className='m-5 orbitron'>Total Invested</h4>
                          <h1 className='m-5 orbitron'>$ {investedAmount}</h1>
                        </div>
                        <div className='statscards text-center'>
                          <h3 className='m-5 zz orbitron'>Total Growth</h3>
                          {portfolioGrowth >= 0 ? (
                          <h1 className='text-[#10b981] m-5 orbitron'> +{portfolioGrowth}%</h1>
                        ) :
                        (
                          <h1 className='text-[#FF6347] m-5 orbitron'> {portfolioGrowth}% </h1>
                        )}
                        </div>
                      </div>
                    
                    {isConnected ?
                    (
                    <>   
                    <br/>
                    <div className="flex justify-center text-blue2">
                      <h2>CHAIN</h2>
                    </div>
                    <br/>
                      <div className="flex justify-center text-blue2">
                          <select  name="address" value={`0x${chainId}`} onChange={networkHandler}>
                            <option value="0x1">Ethereum</option>
                            <option value="0xa4b1">Arbitrum</option>
                          </select>
                        </div>
                        <br/>
                        <div className="flex justify-center text-blue2">
                            <h2>WITHDRAW AMOUNT</h2>
                        </div>
                        <div className='flex justify-center'>
                            <input type="text"   name="allocation" value=""/>
                        </div>  
                        <br/>
                        <div className='flex justify-center'>
                          {onchainDeployed ? (
                                  <button className="btn text-black blue2 orbitron light marg" ref={deployRef}>Deploy</button>
                              ) : (
                                  <button className="btn text-black blue2 orbitron marg" ref={deployRef} onClick={deployFund}>Deploy</button>
                              )}
                            
                            <br/>
                            {withdrawEnabled ? (
                                  <button className="btn text-black blue2 orbitron marg " onClick={withdraw} ref={withdrawRef}>Withdraw</button>
                              ) : (
                                <button className="btn text-black blue2 orbitron light marg" ref={withdrawRef}>Withdraw</button>
                              )}
                        </div>
                        <br/>
                        

                        {currentContract ? 
                          (
                            <div className="flex justify-center text-white">
                              <h2>Contract deployed to {currentContract}</h2>
                            </div>
                          ):<br/>
                          }
                        {currentContract ? 
                          (
                            <div className="flex justify-center text-blue2">
                              <a href={`https://arbiscan.io/address/${currentContract}`} target="_blank" rel="noopener noreferrer">
                                <u>
                                <h3>View recent events</h3>
                                </u>
                              </a>
                            </div>
                          ):<br/>
                          }
                    </>
                    ) : <div className='flex justify-center'>
                            <button className="btn text-black blue2 orbitron " onClick={connectWallet}> ConnectWallet </button>
                        </div>
                    }
                    {loading ? 
                    (<div className='flex justify-center mt-40'>
                      <Loader loading={loading}/>
                    </div>
                    ) : <Table finaldata={finaldata}/>
                    }
                </div>
              }
          </div>
        </div>
      </div>
    );
}


export default Dashboard;